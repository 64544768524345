/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import {
  getDataCollector,
  getDataTrash as onLoadData,
  addTrash as onCreate,
  updateTrash as onUpdate,
  deleteTrash as onDelete,
  resetTrashFlag as resetFlag,
} from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Trash = (props) => {
  const dispatch = useDispatch();

  const successnotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });

  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });

  const [collectorOptions, setCollectorOptions] = useState([]);
  const [collectorOptionsSelected, setCollectorOptionsSelected] = useState("");
  const [page, setPage] = useState(1);
  const [per_page] = useState(50);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState(null);
  const [item, setItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isLoadingAjax, setLoadingAjax] = useState(false);

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const {
    collectorLoadingList,
    collectorList,
    trashList,
    baseLoading,
    baseLoadingList,
    success,
    error,
    message,
  } = useSelector((state) => ({
    collectorLoadingList: state.Collector.loadingList,
    collectorList: state.Collector.collectorList,
    trashList: state.Trash.trashList,
    baseLoading: state.Trash.loading,
    baseLoadingList: state.Trash.loadingList,
    success: state.Trash.success,
    error: state.Trash.error,
    message: state.Trash.message,
  }));

  useEffect(() => {
    setLoadingAjax(collectorLoadingList);
  }, [collectorLoadingList]);

  useEffect(() => {
    if (collectorList && collectorList.items?.length > 0) {
      const data = collectorList.items.map((item) => ({
        value: item.id,
        label: item.trash_bank_name,
      }));
      setCollectorOptions(data);
    }
  }, [collectorList]);

  useEffect(() => {
    if (trashList) {
      setHasNextPage(trashList.has_next_page);
    }

    if (trashList && trashList.items?.length > 0) {
      setData(trashList);
    } else {
      setData(null);
    }
  }, [trashList]);

  useEffect(() => {
    if (success) {
      successnotify(message);
      dispatch(onLoadData({ page: page, per_page: per_page }));
      setmodal_list(false);
      setmodal_delete(false);
      setItem(null);
      setTimeout(() => {
        dispatch(resetFlag("success", false));
        dispatch(resetFlag("message", null));
      }, 500);
      validation.resetForm();
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      errornotify(message);
      setTimeout(() => {
        dispatch(resetFlag("error", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    setLoading(baseLoading);
    setLoadingList(baseLoadingList);
  }, [baseLoading, baseLoadingList]);

  useEffect(() => {
    dispatch(getDataCollector({ page: 1, per_page: 50 }));
    dispatch(onLoadData({ page: page, per_page: per_page }));
  }, []);

  const searchCollector = (keyword) => {
    dispatch(getDataCollector({ page: 1, per_page: 50, search: keyword }));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: item?.id,
      trash_name: item?.trash_name,
      type: item?.type,
      selling_price: item?.selling_price,
      buying_price: item?.buying_price,
      stock: item?.stock,
      unit: item?.unit,
      maximum: item?.maximum,
      status: item?.status,
    },
    validationSchema: Yup.object({
      trash_name: Yup.string().required("Nama Depan kosong"),
      selling_price: Yup.string().required("Harga Jual kosong"),
      buying_price: Yup.string().required("Harga Beli kosong"),
      maximum: Yup.string().required("Maksimum kosong"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const requestBody = {
          id: values["id"],
          trash_name: values["trash_name"],
          type: values["type"],
          selling_price: parseFloat(values["selling_price"]),
          buying_price: parseFloat(values["buying_price"]),
          stock: values["stock"],
          unit: values["unit"],
          maximum: parseFloat(values["maximum"]),
          status: values["status"],
          collectorId: collectorOptionsSelected["value"],
        };
        dispatch(onUpdate(requestBody));
      } else {
        const requestBody = {
          id: values["id"],
          trash_name: values["trash_name"],
          type: values["type"],
          selling_price: parseFloat(values["selling_price"]),
          buying_price: parseFloat(values["buying_price"]),
          stock: values["stock"],
          unit: values["unit"],
          maximum: parseFloat(values["maximum"]),
          status: values["status"],
          collectorId: collectorOptionsSelected["value"],
        };
        dispatch(onCreate(requestBody));
      }
    },
  });

  const deleteItems = () => {
    dispatch(onDelete({ data: { id: [item.id] } }));
  };

  const filterData = (event) => {
    const keyword = event.target.value;
    setPage(1);
    setOffset(1);
    if (keyword.length > 0) {
      dispatch(onLoadData({ page: page, per_page: per_page, search: keyword }));
    } else {
      dispatch(onLoadData({ page: page, per_page: per_page }));
    }
  };

  const formatRupiah = (number) => {
    const numberString = number.toString().replace(/[^,\d]/g, "");
    const parts = numberString.split(",");
    let result = "";
    let count = 0;

    // Format bagian ribuan
    for (let i = parts[0].length - 1; i >= 0; i--) {
      count++;
      result = parts[0].charAt(i) + result;
      if (count % 3 === 0 && i !== 0) {
        result = "." + result;
      }
    }

    // Format bagian desimal
    if (parts[1]) {
      result += "," + parts[1];
    }

    return `Rp${result}`;
  };

  document.title = "Data Sampah - Bank Sampah Sehati";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Data Sampah</h4>
                </CardHeader>

                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <Button
                          color="success"
                          className="add-btn me-1"
                          id="create-btn"
                          onClick={() => {
                            tog_list();
                            setItem(null);
                            setIsEdit(false);
                            validation.handleReset();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Tambah
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div className="search-box ms-2">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Cari..."
                            onChange={filterData}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive table-card mt-3">
                    <table className="table table-hover table-centered align-middle mb-0">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col">Nama Sampah</th>
                          <th scope="col">Tipe</th>
                          <th scope="col">Harga Jual</th>
                          <th scope="col">Harga Beli</th>
                          <th scope="col">Stok</th>
                          <th scope="col">Maksimum</th>
                          <th scope="col">Status</th>
                          <th scope="col">Pemilik</th>
                          <th scope="col">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingList ? (
                          <tr>
                            <td colSpan={9}>
                              <div className="d-flex align-items-center justify-content-center">
                                <Spinner size="sm" color="primary" />
                              </div>
                            </td>
                          </tr>
                        ) : data && data.items.length > 0 ? (
                          data.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.trash_name}</td>
                              <td>{item.type}</td>
                              <td>{`${formatRupiah(item.selling_price)} / ${
                                item.unit
                              }`}</td>
                              <td>{`${formatRupiah(item.buying_price)} / ${
                                item.unit
                              }`}</td>
                              <td>{`${item.stock} ${item.unit}`}</td>
                              <td>{`${item.maximum} ${item.unit}`}</td>
                              <td>
                                {item.status === "active" ? (
                                  <span className="badge badge-soft-success">
                                    {props.t(item.status)}
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-primary">
                                    {props.t(item.status)}
                                  </span>
                                )}
                              </td>
                              <td>
                                {item.tags === null
                                  ? `BSI-${item.collector_code}`
                                  : `BSU-${item.bsu_code}`}
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      onClick={() => {
                                        tog_list();
                                        setItem(item);
                                        setIsEdit(true);
                                        setCollectorOptionsSelected({
                                          value: item.collectorId,
                                          label: item.collector_name,
                                        });
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div className="remove">
                                    <button
                                      className="btn btn-sm btn-danger remove-item-btn"
                                      onClick={() => {
                                        tog_delete();
                                        setItem(item);
                                      }}
                                    >
                                      Hapus
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9}>
                              <div className="d-flex align-item-center justify-content-center">
                                Tidak ada data
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                    <div className="col-sm">
                      <div className="text-muted">
                        <span className="fw-semibold">
                          {loadingList
                            ? "0"
                            : trashList.total_records === 0
                            ? "0"
                            : per_page > trashList.total_records
                            ? `${offset}-${trashList.total_records}`
                            : hasNextPage
                            ? `${offset}-${page * per_page}`
                            : `${offset}-${trashList.total_records}`}
                        </span>{" "}
                        dari{" "}
                        <span className="fw-semibold">
                          {loadingList ? "0" : trashList.total_records}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-auto mt-3 mt-sm-0">
                      <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                        <li
                          className={
                            page > 1 ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page - 1,
                                  per_page: per_page,
                                })
                              );
                              setPage(page - 1);
                              setOffset(offset - per_page);
                            }}
                            className="page-link"
                          >
                            ←
                          </Button>
                        </li>
                        <li
                          className={
                            hasNextPage ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page + 1,
                                  per_page: per_page,
                                })
                              );
                              setPage(page + 1);
                              setOffset(offset + per_page);
                            }}
                            className="page-link"
                          >
                            →
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
          setItem(null);
          setIsEdit(false);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
            setItem(null);
            setIsEdit(false);
          }}
        >
          {" "}
          {isEdit ? "Edit Sampah" : "Tambah Sampah"}{" "}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="tablelist-form"
        >
          <ModalBody>
            <div className="mb-3">
              <label className="form-label">
                Nama Sampah <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="trash_name"
                className="form-control"
                placeholder="Masukkan Nama Sampah"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.trash_name}
                invalid={
                  validation.touched.trash_name && validation.errors.trash_name
                    ? true
                    : false
                }
              />
              {validation.touched.trash_name && validation.errors.trash_name ? (
                <FormFeedback type="invalid">
                  {validation.errors.trash_name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Tipe <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                name="type"
                onChange={validation.handleChange}
                value={validation.values.type}
              >
                <option>Pilih Tipe</option>
                <option value="Organik">Organik</option>
                <option value="Non Organik">Non Organik</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Harga Jual <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="selling_price"
                className="form-control"
                placeholder="Masukkan Harga Jual"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.selling_price}
                invalid={
                  validation.touched.selling_price &&
                  validation.errors.selling_price
                    ? true
                    : false
                }
              />
              {validation.touched.selling_price &&
              validation.errors.selling_price ? (
                <FormFeedback type="invalid">
                  {validation.errors.selling_price}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Harga Beli <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="buying_price"
                className="form-control"
                placeholder="Masukkan Harga Beli"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.buying_price}
                invalid={
                  validation.touched.buying_price &&
                  validation.errors.buying_price
                    ? true
                    : false
                }
              />
              {validation.touched.buying_price &&
              validation.errors.buying_price ? (
                <FormFeedback type="invalid">
                  {validation.errors.buying_price}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Unit <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                name="unit"
                onChange={validation.handleChange}
                value={validation.values.unit}
              >
                <option>Pilih Unit</option>
                <option value="kg">kg</option>
                <option value="pcs">pcs</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Maksimum <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="maximum"
                className="form-control"
                placeholder="Masukkan Maksimum"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.maximum}
                invalid={
                  validation.touched.maximum && validation.errors.maximum
                    ? true
                    : false
                }
              />
              {validation.touched.maximum && validation.errors.maximum ? (
                <FormFeedback type="invalid">
                  {validation.errors.maximum}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Status <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                name="status"
                onChange={validation.handleChange}
                value={validation.values.status}
              >
                <option>Pilih Status</option>
                <option value="active">Aktif</option>
                <option value="inactive">Tidak Aktif</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Bank Sampah Induk <span className="text-danger">*</span>
              </label>
              <Select
                name="collectorId"
                value={collectorOptionsSelected}
                onChange={(sortBy) => {
                  setCollectorOptionsSelected(sortBy);
                }}
                onInputChange={(newValue, actionMeta) => {
                  if (newValue.length > 3) {
                    searchCollector(newValue);
                  }
                }}
                options={collectorOptions}
                classNamePrefix="js-example-data-array"
                isLoading={isLoadingAjax}
                placeholder={"Masukkan Nama Bank Sampah Induk"}
                invalid={
                  validation.touched.collectorId &&
                  validation.errors.collectorId
                    ? true
                    : false
                }
              />
              {validation.touched.collectorId &&
              validation.errors.collectorId ? (
                <FormFeedback type="invalid">
                  {validation.errors.collectorId}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  tog_list();
                  setItem(null);
                  setIsEdit(false);
                }}
              >
                Tutup
              </button>
              <Button
                disabled={loading ? true : false}
                color="success"
                type="submit"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                Simpan
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
          setItem(null);
        }}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <ModalHeader
          toggle={() => {
            tog_delete();
            setItem(null);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <div className="fs-15 mx-4">
              <h4>Hapus Item {item?.trash_name}</h4>
              <p className="text-muted mx-4 mb-0">
                Apakah anda yakin akan menghapus item ini ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => tog_delete()}
            >
              Tutup
            </button>
            <Button
              disabled={loading ? true : false}
              color="danger"
              type="button"
              id="delete-record"
              onClick={() => deleteItems()}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              Hapus
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

Trash.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Trash));
