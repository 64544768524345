/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import {
  getDataCollector,
  getDataBsu as onLoadData,
  addBsu as onCreate,
  updateBsu as onUpdate,
  deleteBsu as onDelete,
  resetBsuFlag as resetFlag,
  getDataProvince,
  getDataRegency,
  getDataSubdistrict,
  getDataVillage,
} from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Bsu = (props) => {
  const dispatch = useDispatch();

  const successnotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });

  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });

  const [provinceOptions, setProvinceOptions] = useState([]);
  const [provinceOptionsSelected, setProvinceOptionsSelected] = useState("");
  const [regencyOptions, setRegencyOptions] = useState([]);
  const [regencyOptionsSelected, setRegencyOptionsSelected] = useState("");
  const [subdistrictOptions, setSubdistrictOptions] = useState([]);
  const [subdistrictOptionsSelected, setSubdistrictOptionsSelected] =
    useState("");
  const [villageOptions, setVillageOptions] = useState([]);
  const [villageOptionsSelected, setVillageOptionsSelected] = useState("");
  const [collectorOptions, setCollectorOptions] = useState([]);
  const [collectorOptionsSelected, setCollectorOptionsSelected] = useState("");
  const [page, setPage] = useState(1);
  const [per_page] = useState(50);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState(null);
  const [item, setItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isLoadingAjax, setLoadingAjax] = useState(false);
  const [isLoadingProvince, setLoadingProvince] = useState(false);
  const [isLoadingRegency, setLoadingRegency] = useState(false);
  const [isLoadingSubdistrict, setLoadingSubdistrict] = useState(false);
  const [isLoadingVillage, setLoadingVillage] = useState(false);

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const {
    provinceList,
    regencyList,
    subdistrictList,
    villageList,
    loadingProvince,
    loadingRegency,
    loadingSubdistrict,
    loadingVillage,
    errorRegion,
    messageRegion,
    collectorLoadingList,
    collectorList,
    bsuList,
    baseLoading,
    baseLoadingList,
    success,
    error,
    message,
  } = useSelector((state) => ({
    provinceList: state.Region.provinceList,
    regencyList: state.Region.regencyList,
    subdistrictList: state.Region.subdistrictList,
    villageList: state.Region.villageList,
    loadingProvince: state.Region.loadingProvince,
    loadingRegency: state.Region.loadingRegency,
    loadingSubdistrict: state.Region.loadingSubdistrict,
    loadingVillage: state.Region.loadingVillage,
    errorRegion: state.Region.error,
    messageRegion: state.Region.message,
    collectorLoadingList: state.Collector.loadingList,
    collectorList: state.Collector.collectorList,
    bsuList: state.Bsu.bsuList,
    baseLoading: state.Bsu.loading,
    baseLoadingList: state.Bsu.loadingList,
    success: state.Bsu.success,
    error: state.Bsu.error,
    message: state.Bsu.message,
  }));

  useEffect(() => {
    setLoadingProvince(loadingProvince);
  }, [loadingProvince]);

  useEffect(() => {
    setLoadingRegency(loadingRegency);
  }, [loadingRegency]);

  useEffect(() => {
    setLoadingSubdistrict(loadingSubdistrict);
  }, [loadingSubdistrict]);

  useEffect(() => {
    setLoadingVillage(loadingVillage);
  }, [loadingVillage]);

  useEffect(() => {
    if (provinceList && provinceList.items?.length > 0) {
      const data = provinceList.items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProvinceOptions(data);
    }
  }, [provinceList]);

  useEffect(() => {
    if (regencyList && regencyList.items?.length > 0) {
      const data = regencyList.items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setRegencyOptions(data);
    }
  }, [regencyList]);

  useEffect(() => {
    if (subdistrictList && subdistrictList.items?.length > 0) {
      const data = subdistrictList.items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSubdistrictOptions(data);
    }
  }, [subdistrictList]);

  useEffect(() => {
    if (villageList && villageList.items?.length > 0) {
      const data = villageList.items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setVillageOptions(data);
    }
  }, [villageList]);

  useEffect(() => {
    setLoadingAjax(collectorLoadingList);
  }, [collectorLoadingList]);

  useEffect(() => {
    if (collectorList && collectorList.items?.length > 0) {
      const data = collectorList.items.map((item) => ({
        value: item.id,
        label: item.trash_bank_name,
      }));
      setCollectorOptions(data);
    }
  }, [collectorList]);

  useEffect(() => {
    if (bsuList) {
      setHasNextPage(bsuList.has_next_page);
    }

    if (bsuList && bsuList.items?.length > 0) {
      setData(bsuList);
    } else {
      setData(null);
    }
  }, [bsuList]);

  useEffect(() => {
    if (success) {
      successnotify(message);
      dispatch(onLoadData({ page: page, per_page: per_page }));
      setmodal_list(false);
      setmodal_delete(false);
      setItem(null);
      setTimeout(() => {
        dispatch(resetFlag("success", false));
        dispatch(resetFlag("message", null));
      }, 500);
      validation.resetForm();
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      errornotify(message);
      setTimeout(() => {
        dispatch(resetFlag("error", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    if (errorRegion) {
      errornotify(messageRegion);
      setTimeout(() => {
        dispatch(resetFlag("error", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [errorRegion, messageRegion]);

  useEffect(() => {
    setLoading(baseLoading);
    setLoadingList(baseLoadingList);
  }, [baseLoading, baseLoadingList]);

  useEffect(() => {
    dispatch(getDataProvince({ page: 1, per_page: 50 }));
    dispatch(getDataCollector({ page: 1, per_page: 50 }));
    dispatch(onLoadData({ page: page, per_page: per_page }));
  }, []);

  const searchProvince = (keyword) => {
    dispatch(getDataProvince({ page: 1, per_page: 50, search: keyword }));
  };

  const searchRegency = (keyword) => {
    dispatch(getDataRegency({ page: 1, per_page: 50, search: keyword }));
  };

  const searchSubdistrict = (keyword) => {
    dispatch(getDataSubdistrict({ page: 1, per_page: 50, search: keyword }));
  };
  const searchVillage = (keyword) => {
    dispatch(getDataVillage({ page: 1, per_page: 50, search: keyword }));
  };

  const searchCollector = (keyword) => {
    dispatch(getDataCollector({ page: 1, per_page: 50, search: keyword }));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: item?.id,
      first_name: item?.user_first_name,
      last_name: item?.user_last_name,
      email: item?.user_email,
      phone_number: item?.user_phone_number,
      status: item?.user_status,
      trash_bank_name: item?.trash_bank_name,
      address: item?.address,
      latlng: item?.latlng,
      collectorId: item?.collector_id,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Nama Depan kosong"),
      phone_number: Yup.string().required("Nomor Ponsel kosong"),
      trash_bank_name: Yup.string().required("Nama Bank Sampah kosong"),
      address: Yup.string().required("Alamat kosong"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const requestBody = {
          id: values["id"],
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          phone_number: values["phone_number"],
          status: values["status"],
          trash_bank_name: values["trash_bank_name"],
          address: values["address"],
          latlng: values["latlng"],
          villageId: villageOptionsSelected["value"],
          collectorId: collectorOptionsSelected["value"],
        };
        dispatch(onUpdate(requestBody));
      } else {
        const requestBody = {
          id: values["id"],
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          phone_number: values["phone_number"],
          status: values["status"],
          trash_bank_name: values["trash_bank_name"],
          address: values["address"],
          latlng: values["latlng"],
          villageId: villageOptionsSelected["value"],
          collectorId: collectorOptionsSelected["value"],
        };
        dispatch(onCreate(requestBody));
      }
    },
  });

  const deleteItems = () => {
    dispatch(onDelete({ data: { id: [item.user_id] } }));
  };

  const filterData = (event) => {
    const keyword = event.target.value;
    setPage(1);
    setOffset(1);
    if (keyword.length > 0) {
      dispatch(onLoadData({ page: page, per_page: per_page, search: keyword }));
    } else {
      dispatch(onLoadData({ page: page, per_page: per_page }));
    }
  };

  document.title = "Bank Sampah Unit - Bank Sampah Sehati";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Data Bank Sampah Unit
                  </h4>
                  <div className="flex-shrink-0">
                    <Select
                      name="collectorId"
                      value={collectorOptionsSelected}
                      onChange={(sortBy) => {
                        setCollectorOptionsSelected(sortBy);
                      }}
                      onInputChange={(newValue, actionMeta) => {
                        if (newValue.length > 3) {
                          searchCollector(newValue);
                        }
                      }}
                      options={collectorOptions}
                      classNamePrefix="js-example-data-array"
                      isLoading={isLoadingAjax}
                      placeholder={"Bank Sampah Induk"}
                    />
                  </div>
                </CardHeader>

                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <Button
                          color="success"
                          className="add-btn me-1"
                          id="create-btn"
                          onClick={() => {
                            tog_list();
                            setItem(null);
                            setIsEdit(false);
                            setCollectorOptionsSelected("");

                            setRegencyOptions([]);
                            setSubdistrictOptions([]);
                            setVillageOptions([]);
                            setProvinceOptionsSelected("");
                            setRegencyOptionsSelected("");
                            setSubdistrictOptionsSelected("");
                            setVillageOptionsSelected("");
                            validation.handleReset();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Tambah
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div className="search-box ms-2">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Cari..."
                            onChange={filterData}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive table-card mt-3">
                    <table className="table table-hover table-centered align-middle mb-0">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col">Kode</th>
                          <th scope="col">Nama Bank Sampah</th>
                          <th scope="col">Alamat</th>
                          <th scope="col">Provinsi</th>
                          <th scope="col">Kabupaten/Kota</th>
                          <th scope="col">Kecamatan</th>
                          <th scope="col">Kelurahan/Desa</th>
                          <th scope="col">Maps</th>
                          <th scope="col">Status</th>
                          <th scope="col">Bank Sampah Induk</th>
                          <th scope="col">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingList ? (
                          <tr>
                            <td colSpan={11}>
                              <div className="d-flex align-items-center justify-content-center">
                                <Spinner size="sm" color="primary" />
                              </div>
                            </td>
                          </tr>
                        ) : data && data.items.length > 0 ? (
                          data.items.map((item, index) => (
                            <tr key={index}>
                              <td>{`BSU-${item.unique_code}`}</td>
                              <td>{item.trash_bank_name}</td>
                              <td>{item.address}</td>
                              <td>{item.province_name}</td>
                              <td>{item.regency_name}</td>
                              <td>{item.subdistrict_name}</td>
                              <td>{item.village_name}</td>
                              <td>
                                <a
                                  target="blank"
                                  href="https://www.google.com/maps/search/?api=1&query=47.5951518%2C-122.3316393"
                                  className="link-primary"
                                >
                                  Lihat
                                </a>
                              </td>
                              <td>
                                {item.user_status === "active" ? (
                                  <span className="badge badge-soft-success">
                                    {props.t(item.user_status)}
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-primary">
                                    {props.t(item.user_status)}
                                  </span>
                                )}
                              </td>
                              <td>{item.collector_name}</td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      onClick={() => {
                                        tog_list();
                                        setItem(item);
                                        setIsEdit(true);
                                        setCollectorOptionsSelected({
                                          value: item.collector_id,
                                          label: item.collector_name,
                                        });
                                        setProvinceOptionsSelected({
                                          value: item.province_id,
                                          label: item.province_name,
                                        });
                                        setRegencyOptionsSelected({
                                          value: item.regency_id,
                                          label: item.regency_name,
                                        });
                                        setSubdistrictOptionsSelected({
                                          value: item.subdistrict_id,
                                          label: item.subdistrict_name,
                                        });
                                        setVillageOptionsSelected({
                                          value: item.village_id,
                                          label: item.village_name,
                                        });
                                        dispatch(
                                          getDataRegency({
                                            page: 1,
                                            per_page: 50,
                                            provinceId: item.province_id,
                                          })
                                        );
                                        dispatch(
                                          getDataSubdistrict({
                                            page: 1,
                                            per_page: 50,
                                            regencyId: item.regency_id,
                                          })
                                        );
                                        dispatch(
                                          getDataVillage({
                                            page: 1,
                                            per_page: 50,
                                            subdistrictId: item.subdistrict_id,
                                          })
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div className="remove">
                                    <button
                                      className="btn btn-sm btn-danger remove-item-btn"
                                      onClick={() => {
                                        tog_delete();
                                        setItem(item);
                                      }}
                                    >
                                      Hapus
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={11}>
                              <div className="d-flex align-item-center justify-content-center">
                                Tidak ada data
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                    <div className="col-sm">
                      <div className="text-muted">
                        <span className="fw-semibold">
                          {loadingList
                            ? "0"
                            : bsuList.total_records === 0
                            ? "0"
                            : per_page > bsuList.total_records
                            ? `${offset}-${bsuList.total_records}`
                            : hasNextPage
                            ? `${offset}-${page * per_page}`
                            : `${offset}-${bsuList.total_records}`}
                        </span>{" "}
                        dari{" "}
                        <span className="fw-semibold">
                          {loadingList ? "0" : bsuList.total_records}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-auto mt-3 mt-sm-0">
                      <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                        <li
                          className={
                            page > 1 ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page - 1,
                                  per_page: per_page,
                                })
                              );
                              setPage(page - 1);
                              setOffset(offset - per_page);
                            }}
                            className="page-link"
                          >
                            ←
                          </Button>
                        </li>
                        <li
                          className={
                            hasNextPage ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page + 1,
                                  per_page: per_page,
                                })
                              );
                              setPage(page + 1);
                              setOffset(offset + per_page);
                            }}
                            className="page-link"
                          >
                            →
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
          setItem(null);
          setIsEdit(false);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
            setItem(null);
            setIsEdit(false);
          }}
        >
          {" "}
          {isEdit ? "Edit Bank Sampah Unit" : "Tambah Bank Sampah Unit"}{" "}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="tablelist-form"
        >
          <ModalBody>
            <div className="mb-3">
              <label className="form-label">
                Nama Bank Sampah <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="trash_bank_name"
                className="form-control"
                placeholder="Masukkan Nama Bank Sampah"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.trash_bank_name}
                invalid={
                  validation.touched.trash_bank_name &&
                  validation.errors.trash_bank_name
                    ? true
                    : false
                }
              />
              {validation.touched.trash_bank_name &&
              validation.errors.trash_bank_name ? (
                <FormFeedback type="invalid">
                  {validation.errors.trash_bank_name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Alamat <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="address"
                className="form-control"
                placeholder="Masukkan Alamat"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.address}
                invalid={
                  validation.touched.address && validation.errors.address
                    ? true
                    : false
                }
              />
              {validation.touched.address && validation.errors.address ? (
                <FormFeedback type="invalid">
                  {validation.errors.address}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Provinsi <span className="text-danger">*</span>
              </label>
              <Select
                name="provinceId"
                value={provinceOptionsSelected}
                onChange={(sortBy) => {
                  setProvinceOptionsSelected(sortBy);
                  dispatch(
                    getDataRegency({
                      page: 1,
                      per_page: 50,
                      provinceId: sortBy.value,
                    })
                  );

                  // setRegencyOptions([]);
                  // setSubdistrictOptions([]);
                  // setVillageOptions([]);
                  // setRegencyOptionsSelected("");
                  // setSubdistrictOptionsSelected("");
                  // setVillageOptionsSelected("");
                }}
                // onInputChange={(newValue, actionMeta) => {
                //     if (newValue.length > 3) {
                //         searchProvince(newValue);
                //     }
                // }}
                options={provinceOptions}
                classNamePrefix="js-example-data-array"
                isLoading={isLoadingProvince}
                placeholder={"Masukkan Provinsi"}
                invalid={
                  validation.touched.provinceId && validation.errors.provinceId
                    ? true
                    : false
                }
              />
              {validation.touched.provinceId && validation.errors.provinceId ? (
                <FormFeedback type="invalid">
                  {validation.errors.provinceId}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Kabupaten/Kota <span className="text-danger">*</span>
              </label>
              <Select
                name="regencyId"
                value={regencyOptionsSelected}
                onChange={(sortBy) => {
                  setRegencyOptionsSelected(sortBy);
                  dispatch(
                    getDataSubdistrict({
                      page: 1,
                      per_page: 50,
                      regencyId: sortBy.value,
                    })
                  );

                  // setSubdistrictOptions([]);
                  // setVillageOptions([]);
                  // setSubdistrictOptionsSelected("");
                  // setVillageOptionsSelected("");
                }}
                // onInputChange={(newValue, actionMeta) => {
                //     if (provinceOptionsSelected !== "" && newValue.length > 3) {
                //         searchRegency(newValue);
                //     }
                // }}
                options={regencyOptions}
                classNamePrefix="js-example-data-array"
                isLoading={isLoadingRegency}
                placeholder={"Masukkan Kabupaten/Kota"}
                invalid={
                  validation.touched.regencyId && validation.errors.regencyId
                    ? true
                    : false
                }
              />
              {validation.touched.regencyId && validation.errors.regencyId ? (
                <FormFeedback type="invalid">
                  {validation.errors.regencyId}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Kecamatan <span className="text-danger">*</span>
              </label>
              <Select
                name="subdistrictId"
                value={subdistrictOptionsSelected}
                onChange={(sortBy) => {
                  setSubdistrictOptionsSelected(sortBy);
                  dispatch(
                    getDataVillage({
                      page: 1,
                      per_page: 50,
                      subdistrictId: sortBy.value,
                    })
                  );

                  // setVillageOptions([]);
                  // setVillageOptionsSelected("");
                }}
                // onInputChange={(newValue, actionMeta) => {
                //     if (regencyOptionsSelected !== "" && newValue.length > 3) {
                //         searchSubdistrict(newValue);
                //     }
                // }}
                options={subdistrictOptions}
                classNamePrefix="js-example-data-array"
                isLoading={isLoadingSubdistrict}
                placeholder={"Masukkan Kecamatan"}
                invalid={
                  validation.touched.subdistrictId &&
                  validation.errors.subdistrictId
                    ? true
                    : false
                }
              />
              {validation.touched.subdistrictId &&
              validation.errors.subdistrictId ? (
                <FormFeedback type="invalid">
                  {validation.errors.subdistrictId}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Kelurahan/Desa <span className="text-danger">*</span>
              </label>
              <Select
                name="villageId"
                value={villageOptionsSelected}
                onChange={(sortBy) => {
                  setVillageOptionsSelected(sortBy);
                }}
                // onInputChange={(newValue, actionMeta) => {
                //     if (subdistrictOptionsSelected !== "" && newValue.length > 3) {
                //         searchVillage(newValue);
                //     }
                // }}
                options={villageOptions}
                classNamePrefix="js-example-data-array"
                isLoading={isLoadingVillage}
                placeholder={"Masukkan Kelurahan/Desa"}
                invalid={
                  validation.touched.villageId && validation.errors.villageId
                    ? true
                    : false
                }
              />
              {validation.touched.villageId && validation.errors.villageId ? (
                <FormFeedback type="invalid">
                  {validation.errors.villageId}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">Koordinat</label>
              <Input
                type="text"
                name="latlng"
                className="form-control"
                placeholder="Masukkan Koordinat"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.latlng}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Bank Sampah Induk <span className="text-danger">*</span>
              </label>
              <Select
                name="collectorId"
                value={collectorOptionsSelected}
                onChange={(sortBy) => {
                  setCollectorOptionsSelected(sortBy);
                }}
                onInputChange={(newValue, actionMeta) => {
                  if (newValue.length > 3) {
                    searchCollector(newValue);
                  }
                }}
                options={collectorOptions}
                classNamePrefix="js-example-data-array"
                isLoading={isLoadingAjax}
                placeholder={"Masukkan Nama Bank Sampah Induk"}
                invalid={
                  validation.touched.collectorId &&
                  validation.errors.collectorId
                    ? true
                    : false
                }
              />
              {validation.touched.collectorId &&
              validation.errors.collectorId ? (
                <FormFeedback type="invalid">
                  {validation.errors.collectorId}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-0">
              <label className="form-label">PIC</label>
            </div>

            <div className="mb-3">
              <label className="form-label">
                Nama Depan <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="first_name"
                className="form-control"
                placeholder="Masukkan Nama Depan"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.first_name}
                invalid={
                  validation.touched.first_name && validation.errors.first_name
                    ? true
                    : false
                }
              />
              {validation.touched.first_name && validation.errors.first_name ? (
                <FormFeedback type="invalid">
                  {validation.errors.first_name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">Nama Belakang</label>
              <Input
                type="text"
                name="last_name"
                className="form-control"
                placeholder="Masukkan Nama Belakang"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.last_name}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Email</label>
              <Input
                type="text"
                name="email"
                className="form-control"
                placeholder="Masukkan Email"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                Nomor Ponsel <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                name="phone_number"
                className="form-control"
                placeholder="Masukkan Nomor Ponsel"
                maxLength={255}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.phone_number}
                invalid={
                  validation.touched.phone_number &&
                  validation.errors.phone_number
                    ? true
                    : false
                }
              />
              {validation.touched.phone_number &&
              validation.errors.phone_number ? (
                <FormFeedback type="invalid">
                  {validation.errors.phone_number}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Status <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                name="status"
                onChange={validation.handleChange}
                value={validation.values.status}
              >
                <option>Pilih Status</option>
                <option value="active">Aktif</option>
                <option value="inactive">Tidak aktif</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  tog_list();
                  setItem(null);
                  setIsEdit(false);
                }}
              >
                Tutup
              </button>
              <Button
                disabled={loading ? true : false}
                color="success"
                type="submit"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                Simpan
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
          setItem(null);
        }}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <ModalHeader
          toggle={() => {
            tog_delete();
            setItem(null);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <div className="fs-15 mx-4">
              <h4>Hapus Item {item?.trash_bank_name}</h4>
              <p className="text-muted mx-4 mb-0">
                Apakah anda yakin akan menghapus item ini ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => tog_delete()}
            >
              Tutup
            </button>
            <Button
              disabled={loading ? true : false}
              color="danger"
              type="button"
              id="delete-record"
              onClick={() => deleteItems()}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              Hapus
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

Bsu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Bsu));
