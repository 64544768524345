import { call, put, takeEvery } from "redux-saga/effects";
import { getDataReportSummarySuccessful, reportApiError } from "./actions";
import { getReportSummary as onLoad } from "../../helpers/backend_helper";
import { GET_REPORT_SUMMARY } from "./actionTypes";

function* fetchData({ parameter }) {
  try {
    const response = yield call(onLoad, parameter);
    yield put(getDataReportSummarySuccessful(response));
  } catch (error) {
    yield put(reportApiError(error));
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_REPORT_SUMMARY, fetchData);
}

export default dashboardSaga;
