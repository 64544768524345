/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import {
  acceptWithdraw,
  getDataWallet as onLoadData,
  resetWalletFlag as resetFlag,
} from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Withdrawal = (props) => {
  const dispatch = useDispatch();

  const successnotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-success text-white",
    });

  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });

  const [page, setPage] = useState(1);
  const [per_page] = useState(50);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState(null);
  const [item, setItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const { walletList, baseLoading, baseLoadingList, success, error, message } =
    useSelector((state) => ({
      walletList: state.Wallet.walletList,
      baseLoading: state.Wallet.loading,
      baseLoadingList: state.Wallet.loadingList,
      success: state.Wallet.success,
      error: state.Wallet.error,
      message: state.Wallet.message,
    }));

  useEffect(() => {
    if (walletList) {
      setHasNextPage(walletList.has_next_page);
    }

    if (walletList && walletList.items?.length > 0) {
      setData(walletList);
    } else {
      setData(null);
    }
  }, [walletList]);

  useEffect(() => {
    if (success) {
      successnotify(message);
      dispatch(
        onLoadData({ page: page, per_page: per_page, filter_note: "withdraw" })
      );
      setmodal_list(false);
      setmodal_delete(false);
      setItem(null);
      setTimeout(() => {
        dispatch(resetFlag("success", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [success, message]);

  useEffect(() => {
    if (error) {
      errornotify(message);
      setTimeout(() => {
        dispatch(resetFlag("error", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    setLoading(baseLoading);
    setLoadingList(baseLoadingList);
  }, [baseLoading, baseLoadingList]);

  useEffect(() => {
    dispatch(
      onLoadData({ page: page, per_page: per_page, filter_note: "withdraw" })
    );
  }, []);

  // const validation = useFormik({
  //     // enableReinitialize : use this flag when initial values needs to be changed
  //     enableReinitialize: true,

  //     initialValues: {
  //         id: item?.id,
  //         first_name: item?.user.first_name,
  //         last_name: item?.user.last_name,
  //         email: item?.user.email,
  //         phone_number: item?.user.phone_number,
  //         status: item?.user.status,
  //         trash_bank_name: item?.trash_bank_name,
  //         address: item?.address,
  //         latlng: item?.latlng,
  //     },
  //     validationSchema: Yup.object({
  //         first_name: Yup.string().required("Nama Depan kosong"),
  //         phone_number: Yup.string().required("Nomor Ponsel kosong"),
  //         trash_bank_name: Yup.string().required("Nama Bank Sampah kosong"),
  //         address: Yup.string().required("Alamat kosong"),
  //         latlng: Yup.string().required("Koordinat kosong"),
  //     }),
  //     onSubmit: (values) => {
  //         if (isEdit) {
  //             dispatch(onUpdate(values));
  //         } else {
  //             dispatch(onCreate(values));
  //         }
  //         validation.resetForm();
  //     },
  // });

  // const deleteItems = () => {
  //     dispatch(onDelete({ data: { id: [item.id] } }));
  // }

  // const filterData = (event) => {
  //     const keyword = event.target.value;
  //     setPage(1);
  //     setOffset(1);
  //     if (keyword.length > 0) {
  //         dispatch(onLoadData({ 'page': page, 'per_page': per_page, 'search': keyword }));
  //     } else {
  //         dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
  //     }
  // }

  const formatRupiah = (number) => {
    const numberString = number.toString().replace(/[^,\d]/g, "");
    const parts = numberString.split(",");
    let result = "";
    let count = 0;

    // Format bagian ribuan
    for (let i = parts[0].length - 1; i >= 0; i--) {
      count++;
      result = parts[0].charAt(i) + result;
      if (count % 3 === 0 && i !== 0) {
        result = "." + result;
      }
    }

    // Format bagian desimal
    if (parts[1]) {
      result += "," + parts[1];
    }

    return `Rp${result}`;
  };

  document.title = "Penarikan Dana - Bank Sampah Sehati";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Penarikan Dana
                  </h4>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Cari..."
                        // onChange={filterData}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </Col>
                </CardHeader>

                <CardBody>
                  {/* <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div>
                                                <Button
                                                    color="success"
                                                    className="add-btn me-1"
                                                    id="create-btn"
                                                    onClick={() => {
                                                        tog_list();
                                                        setItem(null);
                                                        setIsEdit(false);
                                                        validation.handleReset();
                                                    }}>
                                                    <i className="ri-add-line align-bottom me-1"></i> Tambah
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Cari..."
                                                        onChange={filterData}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row> */}
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle mb-0">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col">Pengguna</th>
                          <th scope="col">Jumlah Penarikan</th>
                          <th scope="col">Bank Tujuan</th>
                          <th scope="col">Nomor Rekening</th>
                          <th scope="col">Nama Pemilik Kartu</th>
                          <th scope="col">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingList ? (
                          <tr>
                            <td colSpan={6}>
                              <div className="d-flex align-items-center justify-content-center">
                                <Spinner size="sm" color="primary" />
                              </div>
                            </td>
                          </tr>
                        ) : data && data.items.length > 0 ? (
                          data.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.user}</td>
                              <td>{formatRupiah(item.amount || 0)}</td>
                              <td>{item.bank_name}</td>
                              <td>{item.account_number}</td>
                              <td>{item.cardholder_name}</td>
                              <td>
                                <div
                                  className={
                                    item.status === "pending"
                                      ? "d-block d-flex gap-2"
                                      : "d-none d-flex gap-2"
                                  }
                                >
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      disabled={loading ? true : false}
                                      onClick={() => {
                                        tog_list();
                                        dispatch(
                                          acceptWithdraw({
                                            id: item.id,
                                          })
                                        );
                                      }}
                                    >
                                      {loading ? (
                                        <Spinner size="sm" className="me-2">
                                          {" "}
                                          Loading...{" "}
                                        </Spinner>
                                      ) : null}
                                      Konfirmasi
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <div className="d-flex align-item-center justify-content-center">
                                Tidak ada data
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                    <div className="col-sm">
                      <div className="text-muted">
                        <span className="fw-semibold">
                          {loadingList
                            ? "0"
                            : walletList.total_records === 0
                              ? "0"
                              : per_page > walletList.total_records
                                ? `${offset}-${walletList.total_records}`
                                : hasNextPage
                                  ? `${offset}-${page * per_page}`
                                  : `${offset}-${walletList.total_records}`}
                        </span>{" "}
                        dari{" "}
                        <span className="fw-semibold">
                          {loadingList ? "0" : walletList.total_records}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-auto mt-3 mt-sm-0">
                      <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                        <li
                          className={
                            page > 1 ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page - 1,
                                  per_page: per_page,
                                  filter_note: "withdraw",
                                })
                              );
                              setPage(page - 1);
                              setOffset(offset - per_page);
                            }}
                            className="page-link"
                          >
                            ←
                          </Button>
                        </li>
                        <li
                          className={
                            hasNextPage ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page + 1,
                                  per_page: per_page,
                                  filter_note: "withdraw",
                                })
                              );
                              setPage(page + 1);
                              setOffset(offset + per_page);
                            }}
                            className="page-link"
                          >
                            →
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

Withdrawal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Withdrawal));
