//REGISTER
export const POST_REGISTER = "/auth/admin/login";

//LOGIN
export const POST_LOGIN = "/auth/admin/login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

export const REPORT_SUMMARY = "/strict/report/summary";

//REGION
export const PROVINCE = "/region/province";
export const REGENCY = "/region/regency";
export const SUBDISTRICT = "/region/subdistrict";
export const VILLAGE = "/region/village";

//USER
export const USER = "/strict/user";

//COLLECTOR
export const COLLECTOR = "/strict/collector";

//BSU
export const BSU = "/strict/bsu";

//TRASH
export const TRASH = "/strict/trash";

//CUSTOMER
export const CUSTOMER = "/strict/customer";

//TRANSACTION
export const TRANSACTION = "/strict/transaction";
export const TRANSACTION_DETAILS = "/strict/transaction/details";

//WALLET
export const WALLET = "/strict/wallet";
export const ACCEPT_TOPUP = "/strict/wallet/topup/accept";
export const ACCEPT_WITHDRAW = "/strict/wallet/withdraw/accept";
