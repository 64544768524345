export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_SUCCESSFUL = "GET_WALLET_SUCCESSFUL";

export const ACCEPT_TOPUP = "ACCEPT_TOPUP";
export const ACCEPT_WITHDRAW = "ACCEPT_WITHDRAW";

export const WALLET_API_OK = "WALLET_API_OK";
export const WALLET_API_ERROR = "WALLET_API_ERROR";

export const RESET_WALLET_FLAG = "RESET_WALLET_FLAG";
