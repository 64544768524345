import {
  GET_REPORT_SUMMARY,
  GET_REPORT_SUMMARY_SUCCESSFUL,
  REPORT_API_ERROR,
  RESET_REPORT_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  response: null,
  loading: false,
  error: false,
  message: null,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_SUMMARY:
      return {
        ...state,
        loading: true,
        response: null,
      };

    case GET_REPORT_SUMMARY_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };

    case REPORT_API_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload,
      };

    case RESET_REPORT_FLAG:
      var flag = action.payload.flag;
      var value = action.payload.value;
      var flags = {};
      flags[flag] = value;

      return {
        ...state,
        ...flags,
      };
    default:
      return state;
  }
};

export default Dashboard;
