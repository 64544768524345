import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";

import Dashboard from "./dashboard/reducer";
import Region from "./region/reducer";
import User from "./user/reducer";
import Collector from "./collector/reducer";
import Bsu from "./bsu/reducer";
import Trash from "./trash/reducer";
import Customer from "./customer/reducer";
import Transaction from "./transaction/reducer";
import Wallet from "./wallet/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  Region,
  User,
  Collector,
  Bsu,
  Trash,
  Customer,
  Transaction,
  Wallet,
});

export default rootReducer;
