import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postRegister = (data) => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = (data) => api.create(url.POST_LOGIN, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

export const getReportSummary = (params) => api.get(url.REPORT_SUMMARY, params);

// Region
export const getProvince = (params) => api.get(url.PROVINCE, params);
export const getRegency = (params) => api.get(url.REGENCY, params);
export const getSubdistrict = (params) => api.get(url.SUBDISTRICT, params);
export const getVillage = (params) => api.get(url.VILLAGE, params);

// User
export const getUser = (params) => api.get(url.USER, params);
export const createUser = (data) => api.create(url.USER, data);
export const updateUser = (data) => api.put(url.USER + "/" + data.id, data);
export const deleteUser = (data) => api.delete(url.USER, data);

// Collector
export const getCollector = (params) => api.get(url.COLLECTOR, params);
export const createCollector = (data) => api.create(url.COLLECTOR, data);
export const updateCollector = (data) =>
  api.put(url.COLLECTOR + "/" + data.id, data);
export const deleteCollector = (data) => api.delete(url.COLLECTOR, data);

// Bsu
export const getBsu = (params) => api.get(url.BSU, params);
export const createBsu = (data) => api.create(url.BSU, data);
export const updateBsu = (data) => api.put(url.BSU + "/" + data.id, data);
export const deleteBsu = (data) => api.delete(url.BSU, data);

// Trash
export const getTrash = (params) => api.get(url.TRASH, params);
export const createTrash = (data) => api.create(url.TRASH, data);
export const updateTrash = (data) => api.put(url.TRASH + "/" + data.id, data);
export const deleteTrash = (data) => api.delete(url.TRASH, data);

// Customer
export const getCustomer = (params) => api.get(url.CUSTOMER, params);
export const createCustomer = (data) => api.create(url.CUSTOMER, data);
export const updateCustomer = (data) =>
  api.put(url.CUSTOMER + "/" + data.id, data);
export const deleteCustomer = (data) => api.delete(url.CUSTOMER, data);

//Transaction
export const getTransaction = (params) => api.get(url.TRANSACTION, params);
export const getTransactionDetails = (params) =>
  api.get(url.TRANSACTION_DETAILS, params);

// Wallet
export const getWallet = (params) => api.get(url.WALLET, params);
export const acceptTopup = (data) =>
  api.put(url.ACCEPT_TOPUP + "/" + data.id, data);
export const acceptWithdraw = (data) =>
  api.put(url.ACCEPT_WITHDRAW + "/" + data.id, data);
