import { call, put, takeEvery } from "redux-saga/effects";
import {
  getDataWalletSuccessful,
  walletApiError,
  walletApiOk,
} from "./actions";
import {
  getWallet as onLoad,
  acceptTopup as confirmTopup,
  acceptWithdraw as confirmWithdraw,
} from "../../helpers/backend_helper";
import { ACCEPT_TOPUP, ACCEPT_WITHDRAW, GET_WALLET } from "./actionTypes";

function* fetchData({ parameter }) {
  try {
    const response = yield call(onLoad, parameter);
    yield put(getDataWalletSuccessful(response));
  } catch (error) {
    yield put(walletApiError(error));
  }
}

function* acceptTopup({ payload }) {
  try {
    const response = yield call(confirmTopup, payload);
    yield put(walletApiOk(response.message));
  } catch (error) {
    yield put(walletApiError(error));
  }
}

function* acceptWithdraw({ payload }) {
  try {
    const response = yield call(confirmWithdraw, payload);
    yield put(walletApiOk(response.message));
  } catch (error) {
    yield put(walletApiError(error));
  }
}

function* walletSaga() {
  yield takeEvery(GET_WALLET, fetchData);
  yield takeEvery(ACCEPT_TOPUP, acceptTopup);
  yield takeEvery(ACCEPT_WITHDRAW, acceptWithdraw);
}

export default walletSaga;
