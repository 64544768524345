import React from "react";
import { Navigate } from "react-router-dom";

//Authentication
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Dashboard
import Dashboard from "../pages/Dashboard";

import User from "../pages/User";
import Collector from "../pages/Collector";
import Bsu from "../pages/Bsu";
import Trash from "../pages/Trash";
import Customer from "../pages/Customer";
import Transaction from "../pages/Transaction";
import Balance from "../pages/Wallet/Balance";
import Topup from "../pages/Wallet/Topup";
import Withdrawal from "../pages/Wallet/Withdrawal";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },

  { path: "/user", component: <User /> },
  { path: "/bsi", component: <Collector /> },
  { path: "/bsu", component: <Bsu /> },
  { path: "/trash", component: <Trash /> },
  { path: "/customer", component: <Customer /> },
  { path: "/transaction", component: <Transaction /> },
  { path: "/balance-data", component: <Balance /> },
  { path: "/topup", component: <Topup /> },
  { path: "/withdrawal", component: <Withdrawal /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
