/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Spinner,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import {
  getDataWallet as onLoadData,
  resetWalletFlag as resetFlag,
} from "../../store/actions";

const Balance = () => {
  const dispatch = useDispatch();

  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });

  const [page, setPage] = useState(1);
  const [per_page] = useState(50);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState(null);
  const [loadingList, setLoadingList] = useState(false);

  const { walletList, baseLoadingList, error, message } = useSelector(
    (state) => ({
      walletList: state.Wallet.walletList,
      baseLoadingList: state.Wallet.loadingList,
      error: state.Wallet.error,
      message: state.Wallet.message,
    })
  );

  useEffect(() => {
    if (walletList) {
      setHasNextPage(walletList.has_next_page);
    }

    if (walletList && walletList.items?.length > 0) {
      setData(walletList);
    } else {
      setData(null);
    }
  }, [walletList]);

  useEffect(() => {
    if (error) {
      errornotify(message);
      setTimeout(() => {
        dispatch(resetFlag("error", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    setLoadingList(baseLoadingList);
  }, [baseLoadingList]);

  useEffect(() => {
    dispatch(
      onLoadData({ page: page, per_page: per_page, filter_note: "balance" })
    );
  }, []);

  const formatRupiah = (number) => {
    const numberString = number.toString().replace(/[^,\d]/g, "");
    const parts = numberString.split(",");
    let result = "";
    let count = 0;

    // Format bagian ribuan
    for (let i = parts[0].length - 1; i >= 0; i--) {
      count++;
      result = parts[0].charAt(i) + result;
      if (count % 3 === 0 && i !== 0) {
        result = "." + result;
      }
    }

    // Format bagian desimal
    if (parts[1]) {
      result += "," + parts[1];
    }

    return `Rp${result}`;
  };

  document.title = "Data Saldo - Bank Sampah Sehati";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Data Saldo</h4>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Cari..."
                          // onChange={filterData}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </Col>
                </CardHeader>

                <CardBody>
                  {/* <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div>
                                                <Button
                                                    color="success"
                                                    className="add-btn me-1"
                                                    id="create-btn"
                                                    onClick={() => {
                                                        tog_list();
                                                        setItem(null);
                                                        setIsEdit(false);
                                                        validation.handleReset();
                                                    }}>
                                                    <i className="ri-add-line align-bottom me-1"></i> Tambah
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Cari..."
                                                        onChange={filterData}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row> */}
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle mb-0">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col">Pengguna</th>
                          <th scope="col">Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingList ? (
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex align-items-center justify-content-center">
                                <Spinner size="sm" color="primary" />
                              </div>
                            </td>
                          </tr>
                        ) : data && data.items.length > 0 ? (
                          data.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.user}</td>
                              <td>{formatRupiah(item.total)}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={2}>
                              <div className="d-flex align-item-center justify-content-center">
                                Tidak ada data
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                    <div className="col-sm">
                      <div className="text-muted">
                        <span className="fw-semibold">
                          {loadingList
                            ? "0"
                            : walletList.total_records === 0
                            ? "0"
                            : per_page > walletList.total_records
                            ? `${offset}-${walletList.total_records}`
                            : hasNextPage
                            ? `${offset}-${page * per_page}`
                            : `${offset}-${walletList.total_records}`}
                        </span>{" "}
                        dari{" "}
                        <span className="fw-semibold">
                          {loadingList ? "0" : walletList.total_records}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-auto mt-3 mt-sm-0">
                      <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                        <li
                          className={
                            page > 1 ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page - 1,
                                  per_page: per_page,
                                  filter_note: "balance",
                                })
                              );
                              setPage(page - 1);
                              setOffset(offset - per_page);
                            }}
                            className="page-link"
                          >
                            ←
                          </Button>
                        </li>
                        <li
                          className={
                            hasNextPage ? "page-item" : "page-item disabled"
                          }
                        >
                          <Button
                            onClick={() => {
                              dispatch(
                                onLoadData({
                                  page: page + 1,
                                  per_page: per_page,
                                  filter_note: "balance",
                                })
                              );
                              setPage(page + 1);
                              setOffset(offset + per_page);
                            }}
                            className="page-link"
                          >
                            →
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Balance;
