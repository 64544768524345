import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import user from "../../assets/images/users/user-dummy-img.jpg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getDataReportSummary as onLoadData,
  resetReportFlag as resetFlag,
} from "../../store/actions";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Dashboard = (props) => {
  const dispatch = useDispatch();

  const errornotify = (message) =>
    toast(message, {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
    });

  const [loading, setLoading] = useState(false);

  const { response, baseLoading, error, message } = useSelector((state) => ({
    response: state.Dashboard.response,
    baseLoading: state.Dashboard.loading,
    error: state.Dashboard.error,
    message: state.Dashboard.message,
  }));

  useEffect(() => {
    if (error) {
      errornotify(message);
      setTimeout(() => {
        dispatch(resetFlag("error", false));
        dispatch(resetFlag("message", null));
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    setLoading(baseLoading);
  }, [baseLoading]);

  useEffect(() => {
    dispatch(onLoadData());
  }, []);

  const formatRupiah = (number) => {
    const numberString = number.toString().replace(/[^,\d]/g, "");
    const parts = numberString.split(",");
    let result = "";
    let count = 0;

    // Format bagian ribuan
    for (let i = parts[0].length - 1; i >= 0; i--) {
      count++;
      result = parts[0].charAt(i) + result;
      if (count % 3 === 0 && i !== 0) {
        result = "." + result;
      }
    }

    // Format bagian desimal
    if (parts[1]) {
      result += "," + parts[1];
    }

    return `Rp${result}`;
  };

  const formatDate = (val) => {
    const date = new Date(val);

    const day = date.getDate();
    const month = date.toLocaleDateString("id-ID", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  document.title = "Dasbor - Bank Sampah Sehati";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Col xl={3} md={6}>
                    <Card className="card-animate">
                      <CardBody>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Bank Sampah Induk
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target="559.25"
                              >
                                <CountUp
                                  start={0}
                                  end={response?.total_bsi}
                                  decimals={0}
                                  duration={4}
                                />
                              </span>
                            </h4>
                            <Link
                              to="/bsi"
                              className="text-decoration-underline"
                            >
                              Lihat detail
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span
                              className={
                                "avatar-title rounded fs-3 bg-soft-success"
                              }
                            >
                              <i className={`text-success bx bxs-bank`}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={3} md={6}>
                    <Card className="card-animate">
                      <CardBody>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Bank Sampah Unit
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target="559.25"
                              >
                                <CountUp
                                  start={0}
                                  end={response?.total_bsu}
                                  decimals={0}
                                  duration={4}
                                />
                              </span>
                            </h4>
                            <Link
                              to="/bsu"
                              className="text-decoration-underline"
                            >
                              Lihat detail
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span
                              className={
                                "avatar-title rounded fs-3 bg-soft-info"
                              }
                            >
                              <i className={`text-info bx bx-buildings`}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={3} md={6}>
                    <Card className="card-animate">
                      <CardBody>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Nasabah
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target="559.25"
                              >
                                <CountUp
                                  start={0}
                                  end={response?.total_customer}
                                  decimals={0}
                                  duration={4}
                                />
                              </span>
                            </h4>
                            <Link
                              to="/customer"
                              className="text-decoration-underline"
                            >
                              Lihat detail
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span
                              className={
                                "avatar-title rounded fs-3 bg-soft-warning"
                              }
                            >
                              <i
                                className={`text-warning bx bx-user-circle`}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={3} md={6}>
                    <Card className="card-animate">
                      <CardBody>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Data Sampah
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target="559.25"
                              >
                                <CountUp
                                  start={0}
                                  end={response?.total_trash}
                                  decimals={0}
                                  duration={4}
                                />
                              </span>
                            </h4>
                            <Link
                              to="/trash"
                              className="text-decoration-underline"
                            >
                              Lihat detail
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span
                              className={
                                "avatar-title rounded fs-3 bg-soft-primary"
                              }
                            >
                              <i className={`text-primary bx bx-trash`}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Top Bank Sampah Unit
                        </h4>
                      </CardHeader>

                      <CardBody>
                        <div className="table-responsive table-card">
                          <table className="table table-centered table-hover align-middle mb-0">
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan={2}>
                                    <div className="d-flex align-items-center justify-content-center">
                                      <Spinner size="sm" color="primary" />
                                    </div>
                                  </td>
                                </tr>
                              ) : response && response.top_bsu.length > 0 ? (
                                response.top_bsu.map((item, key) => (
                                  <tr key={key}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                          <img
                                            src={user}
                                            alt=""
                                            className="avatar-sm p-2"
                                          />
                                        </div>
                                        <div>
                                          <h5 className="fs-14 my-1 fw-medium">
                                            {item.bsu_name}
                                          </h5>
                                          <span className="text-muted">
                                            {item.address}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="fs-14 mb-0">
                                        {item.purchase_count}
                                      </h5>
                                      <span className="text-muted">Order</span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={2}>
                                    <div className="d-flex align-item-center justify-content-center">
                                      Tidak ada data
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={5}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Transaksi Terbaru
                        </h4>
                      </CardHeader>

                      <CardBody>
                        <div className="table-responsive table-card">
                          <table className="table table-hover table-centered align-middle mb-0">
                            <thead className="text-muted table-light">
                              <tr>
                                <th scope="col">Tanggal</th>
                                <th scope="col">Pengguna</th>
                                <th scope="col">Total</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan={4}>
                                    <div className="d-flex align-items-center justify-content-center">
                                      <Spinner size="sm" color="primary" />
                                    </div>
                                  </td>
                                </tr>
                              ) : response &&
                                response.latest_transactions.length > 0 ? (
                                response.latest_transactions.map(
                                  (item, key) => (
                                    <tr key={key}>
                                      <td>{formatDate(item?.created_on)}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="flex-shrink-0 me-2">
                                            <img
                                              src={user}
                                              alt=""
                                              className="avatar-xs rounded-circle"
                                            />
                                          </div>
                                          <div className="flex-grow-1">
                                            {item.user}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="text-success">
                                          {formatRupiah(item.amount)}
                                        </span>
                                      </td>
                                      <td>
                                        {item.status === "settlement" ? (
                                          <span className="badge badge-soft-success">
                                            {props.t(item.status)}
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-primary">
                                            {props.t(item.status)}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan={4}>
                                    <div className="d-flex align-item-center justify-content-center">
                                      Tidak ada data
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl={4}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Top Sampah
                        </h4>
                      </CardHeader>

                      <CardBody>
                        <div className="table-responsive table-card">
                          <table className="table table-hover table-centered align-middle mb-0">
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan={4}>
                                    <div className="d-flex align-items-center justify-content-center">
                                      <Spinner size="sm" color="primary" />
                                    </div>
                                  </td>
                                </tr>
                              ) : response && response.top_trash.length > 0 ? (
                                response.top_trash.map((item, key) => (
                                  <tr key={key}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <h5 className="fs-14 my-1">
                                            {item.trash_name}
                                          </h5>
                                          <span className="text-muted">
                                            {item.bsi_name}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="fs-14 my-1 fw-normal">
                                        {formatRupiah(item.price)}
                                      </h5>
                                      <span className="text-muted">Harga</span>
                                    </td>
                                    <td>
                                      <h5 className="fs-14 my-1 fw-normal">
                                        {item.purchase_count}
                                      </h5>
                                      <span className="text-muted">Order</span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>
                                    <div className="d-flex align-item-center justify-content-center">
                                      Tidak ada data
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Dashboard));
