import {
  GET_WALLET,
  GET_WALLET_SUCCESSFUL,
  WALLET_API_ERROR,
  WALLET_API_OK,
  RESET_WALLET_FLAG,
  ACCEPT_TOPUP,
  ACCEPT_WITHDRAW,
} from "./actionTypes";

const INIT_STATE = {
  walletList: [],
  loading: false,
  loadingList: false,
  success: false,
  error: false,
  message: null,
};

const Wallet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WALLET:
      return {
        ...state,
        loadingList: true,
        walletList: [],
      };

    case GET_WALLET_SUCCESSFUL:
      return {
        ...state,
        loadingList: false,
        walletList: action.payload,
      };

    case ACCEPT_TOPUP:
      return {
        ...state,
        loading: true,
      };

    case ACCEPT_WITHDRAW:
      return {
        ...state,
        loading: true,
      };

    case WALLET_API_OK:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };

    case WALLET_API_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };

    case RESET_WALLET_FLAG:
      var flag = action.payload.flag;
      var value = action.payload.value;
      var flags = {};
      flags[flag] = value;

      return {
        ...state,
        ...flags,
      };
    default:
      return state;
  }
};

export default Wallet;
