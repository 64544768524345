import {
  GET_REPORT_SUMMARY,
  GET_REPORT_SUMMARY_SUCCESSFUL,
  REPORT_API_ERROR,
  RESET_REPORT_FLAG,
} from "./actionTypes";

export const getDataReportSummary = (parameter) => {
  return {
    type: GET_REPORT_SUMMARY,
    parameter: parameter,
  };
};

export const getDataReportSummarySuccessful = (data) => {
  return {
    type: GET_REPORT_SUMMARY_SUCCESSFUL,
    payload: data,
  };
};

export const reportApiError = (error) => {
  return {
    type: REPORT_API_ERROR,
    payload: error,
  };
};

export const resetReportFlag = (flag, value) => {
  return {
    type: RESET_REPORT_FLAG,
    payload: { flag, value },
  };
};
